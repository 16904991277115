import { useVideoConfig } from '../config/VideoAppContext';
export const useShowPosterImage = shows => {
  const {
    supportShowPosterImage
  } = useVideoConfig();
  const hasShows = shows && shows.length > 0;
  const showPosterImage = hasShows ? shows?.every(show => !show.posterImageIsFallbackUrl) ?? supportShowPosterImage : supportShowPosterImage;
  const aspectRatio = showPosterImage ? 2 / 3 : 16 / 9;
  return {
    showPosterImage,
    aspectRatio
  };
};